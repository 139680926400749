.h-screen {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.min-h-screen {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
}

.max-h-screen {
  max-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  max-height: calc(var(--vh, 1vh) * 100);
}

.credit-agreement {
  width: 75%;
  margin: 10px auto;
}

@media screen and (max-width:950px) {
  .credit-agreement {
    width: 98%;
  }
}